<template>
  <form @submit.prevent="saveItem">
    <b-card title="Thematic UI Form">
      <b-row>
        <b-col cols="12" md="4" v-for="(items, key) in settings" :key="key">
          <div class="form-group" v-if="items.key.includes('image')">
            <label class="text-capitalize">{{
              items.key.replaceAll("_", " ").toLowerCase()
            }}</label
            ><br />
            <b-form-file
              placeholder="Choose a file or drop it here... (Only PNG)"
              drop-placeholder="Drop file here..."
              accept="image/png"
              @change="changeFile($event, items.key)"
            />
            <small>Current file:</small><br />
            <img
              v-if="image_url[items.key]"
              :src="image_url[items.key]"
              class="media_file"
            />
            <small
              v-for="(validation, index) in validations[items.key]"
              :key="`errorName${index}`"
              class="text-danger"
              >{{ validation }}</small
            >
          </div>
          <div class="form-group" v-else>
            <label class="text-capitalize">{{
              items.key.replaceAll("_", " ").toLowerCase()
            }}</label>
            <input
              type="text"
              :ref="items.key"
              v-model="inputSettings[key]"
              class="form-control"
            />
            <small
              v-for="(validation, index) in validations[items.key]"
              :key="`errorName${index}`"
              class="text-danger"
              >{{ validation }}</small
            >
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-col cols="12" class="p-0 mt-2">
      <template v-if="isLoading">
        <button
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary"
          disabled
        >
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </button>
      </template>
      <template v-else>
        <button
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary"
        >
          Save
        </button>
      </template>
    </b-col>
  </form>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import { required } from "@validations";
import {
  BCard,
  BCardText,
  BForm,
  BFormFile,
  BButton,
  BCol,
  BRow,
  BSpinner,
} from "bootstrap-vue";
import { successNotification, errorNotification } from "@/auth/utils";
export default {
  components: {
    BCard,
    BCardText,
    BForm,
    BFormFile,
    BButton,
    BCol,
    BRow,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      required,
      settings: [],
      inputSettings: [],
      image_url: {},
      isLoading: false,
      validations: {},
    };
  },

  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },

  methods: {
    changeFile(event, key) {
      const file = event.target.files[0];
      this.inputSettings[key] = file;
      this.showFile(file, key);
    },
    showFile(file, key) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageUrl = e.target.result;
        this.$set(this.image_url, key, imageUrl);
      };
      reader.readAsDataURL(file);
    },
    saveItem() {
      this.isLoading = true;
      const payload = new FormData();
      for (let index = 0; index < this.settings.length; index++) {
        const element = this.settings[index];
        if (element.key.includes("image")) {
          payload.append(element.key, this.inputSettings[element.key]);
        } else {
          payload.append(element.key, this.$refs[element.key][0].value);
        }
      }
      for (const key of Object.keys(this.inputSettings)) {
        if (key.includes("image")) {
          payload.append(key, this.inputSettings[key]);
        }
      }
      this.$http
        .post("/api/v1/admin/settings/thematic-ui", payload, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          successNotification(this, "Success", "Settings App Berhasil diedit!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
          }
        });
    },
    getData() {
      this.isLoading = true;
      this.$http.get("/api/v1/admin/settings/thematic-ui").then((response) => {
        this.settings = response.data.data;
        this.inputSettings = this.settings.map((e) => {
          if (
            e.value &&
            typeof e.value === "string" &&
            e.value.includes(".png")
          ) {
            this.$set(this.image_url, e.key, e.value);
            return e.value.replace(".png", ""); // Remove the ".png" extension
          } else {
            return e.value;
          }
        });
        this.isLoading = false;
      });
    },
  },

  created() {
    this.getData();
  },
};
</script>

<style lang="scss">
.image__hint {
  font-size: 10px;
  margin-bottom: 0;
}
.media_file {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
